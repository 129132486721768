<form [formGroup]="aboutYouComponentForm" class="qui-form-layout__col-center flex flex-col gap-400" (submit)="handleSubmit($event)" id="quote-form">
  <button type="button" (click)="open()" class="card radio-card flex w-full items-center justify-between border-neutral-300 xs:hidden" aria-label="Learn more about what to expect">
    <div class="flex items-center gap-200">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="var(--color-primary)"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        class="h-[2em] w-[2em]"
        viewBox="0 0 24 24"
      >
        <path d="M15 14c.2-1 .7-1.7 1.5-2.5 1-.9 1.5-2.2 1.5-3.5A6 6 0 0 0 6 8c0 1 .2 2.2 1.5 3.5.7.7 1.3 1.5 1.5 2.5M9 18h6M10 22h4" />
      </svg>
      <strong>What to expect</strong>
    </div>
    <span class="link text-neutral-900">Learn more</span>
  </button>

  @if (allFieldsLocked()) {
    <app-locked-quote-alert></app-locked-quote-alert>
  }
  <app-form-card [title]="'We\'ll need some personal information to build your quote'" icon="lucideCircleUserRound">
    <ng-template #subcopyTemplate>
      <p>Kin is a fully licensed agency committed to offering affordable insurance policies in {{ userState() }}*.</p>
      <a routerLink="/resume" class="link inline-block font-bold" data-testid="route-to-resume">Resume your quote</a>
    </ng-template>

    <div class="mt-600 grid grid-cols-1 gap-x-400 gap-y-400 sm:grid-cols-6 md:gap-y-600">
      <app-text-input
        class="sm:col-span-3"
        [control]="aboutYouComponentForm.controls.firstName"
        label="First name"
        id="first-name"
        data-testid="first-name"
        [required]="true"
        [errorMessages]="errorMessages.firstName"
        [readonly]="isFieldLocked('firstName')"
      ></app-text-input>

      <app-text-input
        class="sm:col-span-3"
        [control]="aboutYouComponentForm.controls.lastName"
        label="Last name"
        id="last-name"
        data-testid="last-name"
        [required]="true"
        [errorMessages]="errorMessages.lastName"
        [readonly]="isFieldLocked('lastName')"
      ></app-text-input>

      <app-text-input
        class="sm:col-span-6"
        [control]="aboutYouComponentForm.controls.dob"
        label="Date of birth"
        [maskito]="maskitoDateOptions"
        inputmode="numeric"
        placeholder="MM/DD/YYYY"
        id="dob"
        data-testid="birthdate"
        autocomplete="birthday"
        type="text"
        [required]="true"
        [readonly]="isFieldLocked('dob')"
        [errorMessages]="errorMessages.dob"
        helpText="We need your birth date to get your insurance score for the most accurate quote."
      ></app-text-input>

      <app-text-input
        class="sm:col-span-3"
        [control]="aboutYouComponentForm.controls.phoneNumber"
        label="Phone"
        id="phone"
        data-testid="phone"
        type="tel"
        placeholder="(   )    -    "
        [maskito]="maskitoPhoneOptions"
        [required]="true"
        [errorMessages]="errorMessages.phoneNumber"
        helpText="We'll use this to contact you about your policy."
        [readonly]="isFieldLocked('phoneNumber')"
      ></app-text-input>

      <app-text-input
        [control]="aboutYouComponentForm.controls.email"
        class="sm:col-span-3"
        label="Email"
        id="email"
        data-testid="email"
        type="email"
        [required]="true"
        [errorMessages]="errorMessages.email"
        helpText="This will be used to create your account."
        [readonly]="isFieldLocked('email')"
      ></app-text-input>
    </div>
  </app-form-card>

  <div class="card md:px-500 md:py-400">
    <label class="checkbox mb-200 gap-100" for="tcpa-consent" id="tcpa-consent-label" data-testid="tcpa-consent-label">
      <input
        class="checkbox__control h-[--space-300] w-[--space-300]"
        type="checkbox"
        value="yes"
        id="tcpa-consent"
        data-testid="tcpa-consent-checkbox"
        [formControl]="aboutYouComponentForm.controls.hasGivenTcpaConsent"
        [attr.aria-invalid]="aboutYouComponentForm.controls.hasGivenTcpaConsent.invalid && aboutYouComponentForm.controls.hasGivenTcpaConsent.touched"
        [attr.aria-describedby]="
          aboutYouComponentForm.controls.hasGivenTcpaConsent.errors && aboutYouComponentForm.controls.hasGivenTcpaConsent.touched ? 'tcpa-consent-error tcpa-consent-text' : 'tcpa-consent-text'
        "
      />

      <span class="checkbox__label disclaimer-small flex flex-col gap-100 font-regular leading-none" id="tcpa-consent-text">
        @if (showTcpaError()) {
          <div data-testid="tcpa-visibility-error-message" class="error-message form-help--invalid flex items-center gap-100 leading-200" id="tcpa-visibility-error" #tcpaVisibilityError>
            <ng-icon name="lucideCircleAlert" class="!overflow-visible" aria-hidden="true"></ng-icon>
            To proceed, you must scroll to the bottom of the consent details to review all required information
          </div>
        }

        @if (aboutYouComponentForm.controls.hasGivenTcpaConsent.errors && aboutYouComponentForm.controls.hasGivenTcpaConsent.touched) {
          <div data-testid="tcpa-consent-error-message" class="error-message form-help--invalid flex items-center gap-100" id="tcpa-consent-error">
            <ng-icon name="lucideCircleAlert" class="!overflow-visible" aria-hidden="true"></ng-icon>
            Please check the box to continue
          </div>
        }

        @if (isFloridaResident()) {
          <p data-testid="fl-content" class="disclaimer-small">
            I agree to the
            <a href="https://www.kin.com/terms" target="_blank" class="link disclaimer-small">Kin Terms of Service</a>
            and <a href="https://www.kin.com/privacy" target="_blank" class="link disclaimer-small">Privacy Policy</a> and authorize Kin Insurance, Inc. and its affiliates (“Kin”) to obtain a
            credit-based insurance score for myself and any co-applicant(s), for underwriting or rating purposes, <strong>which will not affect my credit score</strong>. In Florida, the Department of
            Financial Services offers free financial literacy programs to assist you with insurance-related questions, including how credit works and how credit scores are calculated. To learn more,
            visit <a href="https://www.MyFloridaCFO.com" target="_blank" rel="noopener noreferrer" class="link disclaimer-small">www.MyFloridaCFO.com</a>. I authorize Kin to contact me at the email
            and/or phone number I provided for marketing purposes, using an automatic telephone dialing system and/or prerecorded voice even if my number is on a Do Not Call list. I acknowledge that I
            can revoke consent at any time by contacting Kin at <a href="tel:{{ csPhone }}" class="link disclaimer-small">{{ csPhone | phone }}</a> or
            <a href="mailto:support@kin.com" class="link disclaimer-small">support&#64;kin.com</a>.
            <strong>(Required)</strong>
          </p>
        } @else {
          <p class="disclaimer-small">
            I agree to the
            <a href="https://www.kin.com/terms" target="_blank" class="link disclaimer-small">Kin Terms of Service</a>
            and
            <a href="https://www.kin.com/privacy" target="_blank" class="link disclaimer-small">Privacy Policy</a>
            and authorize Kin Insurance, Inc. and its affiliates (“Kin”) to obtain a credit-based insurance score for myself and any co-applicant(s), for underwriting or rating purposes, which will
            not affect my credit score. I authorize Kin to contact me at the email and/or phone number I provided for marketing purposes, using an automatic telephone dialing system and/or prerecorded
            voice even if my number is on a Do Not Call list. I acknowledge that I can revoke consent at any time by contacting Kin at
            <a href="tel:{{ csPhone }}" class="link disclaimer-small">{{ csPhone | phone }}</a
            >, or <a href="mailto:support@kin.com" class="link disclaimer-small">support&#64;kin.com</a>.
            <strong>(Required)</strong>
          </p>
        }
      </span>
    </label>

    <label class="checkbox mb-200 gap-100" for="eig-consent" id="eig-consent-label" data-testid="eig-consent-label">
      <input
        class="checkbox__control h-[--space-300] w-[--space-300]"
        type="checkbox"
        value="yes"
        id="eig-consent"
        data-testid="eig-consent-checkbox"
        [formControl]="aboutYouComponentForm.controls.hasGivenEigConsent"
        [attr.aria-invalid]="aboutYouComponentForm.controls.hasGivenEigConsent.invalid && aboutYouComponentForm.controls.hasGivenEigConsent.touched"
        [attr.aria-describedby]="
          aboutYouComponentForm.controls.hasGivenEigConsent.errors && aboutYouComponentForm.controls.hasGivenEigConsent.touched ? 'eig-consent-error eig-consent-text' : 'eig-consent-text'
        "
      />
      <span class="flex flex-col gap-100">
        @if (aboutYouComponentForm.controls.hasGivenEigConsent.errors && aboutYouComponentForm.controls.hasGivenEigConsent.touched) {
          <div data-testid="eig-consent-error-message" class="error-message form-help--invalid disclaimer-small flex items-center gap-100 leading-none" id="eig-consent-error">
            <ng-icon name="lucideCircleAlert" class="!overflow-visible" aria-hidden="true"></ng-icon>
            Please check the box to continue
          </div>
        }
        <p class="disclaimer-small">
          I agree to the Elite Insurance Group, Inc. (“EIG”)
          <a href="https://about.eliteinsurancegroup.com/pages/terms-of-use" target="_blank" rel="noopener noreferrer" class="link disclaimer-small">Terms of Use</a> and
          <a href="https://about.eliteinsurancegroup.com/pages/privacy-policy" target="_blank" rel="noopener noreferrer" class="link disclaimer-small">Privacy Policy</a> and authorize EIG to contact
          me at the email and/or phone number I provided for marketing purposes, including using an automatic telephone dialing system and/or pre-recorded voice even if my number is on a Do Not Call
          list. I acknowledge that I can revoke consent at any time by contacting EIG at <a href="tel:8556644900" class="link disclaimer-small">(855) 664-4900</a> or
          <a href="mailto:insurance@eliteinsurancegroup.com" class="link disclaimer-small">insurance&#64;eliteinsurancegroup.com</a>. <strong>(Required)</strong>
        </p>
      </span>
    </label>

    <label class="checkbox gap-100" for="sms-opt-in" data-testid="sms-opt-in-label" id="sms-opt-in-label">
      <input
        class="checkbox__control h-[--space-300] w-[--space-300]"
        type="checkbox"
        value="yes"
        id="sms-opt-in"
        data-testid="sms-checkbox"
        [formControl]="aboutYouComponentForm.controls.hasOptedIntoSms"
      />

      <span class="checkbox__label disclaimer-small font-regular">I agree to receive SMS texts for Kin Insurance Group promotional purposes. <strong>(Optional)</strong></span>
    </label>

    <p class="mt-300 text-[0.6875rem] text-neutral-800">
      *Policies are marketed, solicited, distributed, and serviced by
      <a href="https://www.kin.com/kin-insurance-network-distributor/" target="_blank" class="link text-[0.6875rem]"> Kin Insurance Network Distributor, LLC ("KIND") </a>
      or may be referred to an affiliated licensed agency. KIND is a licensed producer agency, and, where applicable, surplus lines broker.
    </p>
  </div>
</form>

<app-form-card class="qui-form-layout__col-rail hidden xs:visible xs:block">
  <div class="flex flex-col gap-200">
    <h2 class="flex flex-col gap-300 text-100 font-bold leading-[1.375]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="var(--color-primary)"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        class="h-[2em] w-[2em]"
        viewBox="0 0 24 24"
      >
        <path d="M15 14c.2-1 .7-1.7 1.5-2.5 1-.9 1.5-2.2 1.5-3.5A6 6 0 0 0 6 8c0 1 .2 2.2 1.5 3.5.7.7 1.3 1.5 1.5 2.5M9 18h6M10 22h4" />
      </svg>
      What to expect
    </h2>
    <p>On average, it takes customers under 3 minutes to answer our questions and see a quote.</p>
    <ol class="flex list-inside list-decimal flex-col gap-300">
      <li>We'll ask a handful of questions about you and your home.</li>
      <li>We'll gather additional info about your home from our trusted sources.</li>
      <li>See your personalized online quote!</li>
    </ol>
  </div>
</app-form-card>

<kin-dialog [open]="isDialogOpen" (kin-dialog-hide)="close()" (kin-dialog-show)="open()" scrollBackdrop>
  <h2 class="font-bold" slot="header">What to expect</h2>
  <p class="mb-4">On average, it takes customers under 3 minutes to answer our questions and see a quote.</p>
  <ol class="flex list-inside list-decimal flex-col gap-300">
    <li>We'll ask a handful of questions about you and your home.</li>
    <li>We'll gather additional info about your home from our trusted sources.</li>
    <li>See your personalized online quote!</li>
  </ol>
</kin-dialog>
